import {
    WealthForecastConfiguration,
    WealthForecastConfigurationResponse,
} from "@bankingright-dashboard/interfaces";
import { LoadingIndicator, RefreshButton } from "@bankingright-dashboard/ui";
import { normalize } from "@bankingright-dashboard/utils";
import {
    Card,
    CardBody,
    CardHeader,
    Flex,
    Heading,
    VStack,
} from "@chakra-ui/react";
import {
    useApiUrl,
    useCustom,
    useCustomMutation,
    useNotification,
    useResource,
    useTranslate,
} from "@refinedev/core";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
    CreatableSelectFormControl,
    DatePickerFormControl,
    MarkdownEditorFormControl,
    InputFormControl,
    InputFormType,
    CreatableSelectType,
} from "@bankingright-dashboard/table";
import { SaveButton } from "@bankingright-dashboard/ui";

const fieldWidth = 40;

type FormData = {
    accountIds: Array<string>;
    fromDate: Date;
};

export const AccountImport: React.FC = () => {
    const translate = useTranslate();
    const { resource } = useResource();
    const { open } = useNotification();
    const apiUrl = useApiUrl();
    const url = `${apiUrl}/v1/${resource?.meta?.parent}/${resource!.name}`;

    const {
        control,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<FormData>();

    const { mutate, isLoading } = useCustomMutation();

    const showLoading = isLoading;

    const pageTitle = translate(
        `${normalize(resource!.name)}.titles.overview`,
        resource!.name
    );

    const onSubmit = (data: FormData) => {
        mutate(
            {
                url: url,
                method: "post",
                values: {
                    accountIds: data.accountIds,
                    fromDate: data.fromDate,
                },
            },
            {
                onSuccess: () => {
                    open?.({
                        type: "success",
                        message: pageTitle,
                        description:
                            "The import has been started. It can take some time before its done.",
                    });
                },
            }
        );
    };

    return (
        <Card bg="chakra-body-bg">
            <CardHeader>
                <Flex>
                    <Heading size="lg">{pageTitle}</Heading>
                </Flex>
            </CardHeader>

            <CardBody>
                <VStack align="flex-start">
                    <CreatableSelectFormControl
                        control={control}
                        id="accountIds"
                        name="accountIds"
                        label="Account ID(s)"
                        placeholder="Which Account ID(s) to import?"
                        type={CreatableSelectType.array}
                        isRequired={true}
                    />
                    <DatePickerFormControl
                        control={control}
                        id="fromDate"
                        name="fromDate"
                        label="From Date"
                        isRequired={true}
                    />

                    <SaveButton
                        onClick={handleSubmit(onSubmit)}
                        isLoading={isLoading}
                        mt={5}
                    />
                </VStack>
            </CardBody>

            <LoadingIndicator isLoading={showLoading} />
        </Card>
    );
};
