import { useMsal } from "@azure/msal-react";
import {
    BankingRightAccessControlProvider,
    BankingRightAuthProvider,
    BankingRightDataProvider,
} from "@bankingright-dashboard/providers";
import { notificationProvider } from "@bankingright-dashboard/ui";
import { ChakraProvider } from "@chakra-ui/react";
import { I18nProvider, Refine } from "@refinedev/core";
import routerProvider from "@refinedev/react-router-v6";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { BrowserRouter } from "react-router-dom";
import { doecoeTheme as theme } from "theme/theme";
import { loginRequest } from "./msalConfig";
import { DoecoeResources } from "./resources";
import { DoecoeRoutes } from "./routes";

const App: React.FC = () => {
    const { t, i18n } = useTranslation();
    const { inProgress, instance } = useMsal();

    const i18nProvider: I18nProvider = {
        translate: (key: string, defaultMessage?: string) => {
            return t(key, defaultMessage ?? key);
        },
        changeLocale: (lang: string) => i18n.changeLanguage(lang),
        getLocale: () => i18n.language,
    };

    const axiosInstance = axios.create();
    const apiUrl = process.env.REACT_APP_API_URL!;
    // const apiUrl = "https://private-f72c34-bankingrightmobilemanagementapi.apiary-mock.com";


    const authProvider = BankingRightAuthProvider({
        tokenRequest: loginRequest,
        instance: instance,
    });

    const dataProvider = BankingRightDataProvider({
        apiUrl: apiUrl,
        httpClient: axiosInstance,
        authProvider: authProvider
    });

    const accessControlProvider = BankingRightAccessControlProvider({
        apiUrl: apiUrl,
        httpClient: axiosInstance,
    });
    const accessControlEnabled =
        (process.env.REACT_APP_ACCESS_CONTROL_ENABLED ?? "true") === "true";

    if (inProgress === "login" || inProgress === "handleRedirect") {
        return <div>Loading...</div>;
    }

    return (
        <ChakraProvider theme={theme}>
            <BrowserRouter basename={process.env.PUBLIC_URL}>
                <Refine
                    notificationProvider={notificationProvider}
                    routerProvider={{
                        ...routerProvider,
                    }}
                    dataProvider={dataProvider}
                    // dataProvider={BankingRightDataProvider({ "apiUrl": "https://private-f72c34-bankingrightmobilemanagementapi.apiary-mock.com" })}
                    authProvider={authProvider}
                    i18nProvider={i18nProvider}
                    resources={DoecoeResources}
                    accessControlProvider={
                        accessControlEnabled ? accessControlProvider : undefined
                    }
                >
                    <DoecoeRoutes />
                </Refine>
            </BrowserRouter>
        </ChakraProvider>
    );
};

export default App;
