import { FormType } from "@bankingright-dashboard/enums";
import {
    CreatableSelectFormControl,
    InputFormControl,
    CreatableSelectType
} from "@bankingright-dashboard/table";
import { Create, Edit } from "@bankingright-dashboard/ui";
import { useTranslate } from "@refinedev/core";
import { useForm } from "@refinedev/react-hook-form";
import { IAccountAccess } from "interfaces";
import { VStack } from "@chakra-ui/react";

interface AccountAccessFormProps {
    type: FormType;
}

export const AccountAccessForm = ({ type }: AccountAccessFormProps) => {
    const translate = useTranslate();

    const {
        refineCore: { formLoading, queryResult },
        saveButtonProps,
        register,
        formState: { errors },
        resetField,
        control,
    } = useForm<IAccountAccess>();

    const form = () => {
        return (
            <VStack>
                <InputFormControl
                    name="userId"
                    label="User ID"
                    control={control}
                />
                <CreatableSelectFormControl
                    control={control}
                    id="accounts"
                    name="accounts"
                    label="Linked Accounts"
                    placeholder="Provide the account IDs that should be linked to the user"
                    type={CreatableSelectType.array}

                />
            </VStack>
        );
    };

    switch (type) {
        case FormType.create:
            return (
                <Create
                    isLoading={formLoading}
                    saveButtonProps={saveButtonProps}
                >
                    {form()}
                </Create>
            );
        case FormType.edit:
            return (
                <Edit isLoading={formLoading} saveButtonProps={saveButtonProps}>
                    {form()}
                </Edit>
            );
    }
};
