import { ResourceAction } from "@bankingright-dashboard/enums";
import { SimpleList } from "@bankingright-dashboard/table";
import { ColumnDef } from "@tanstack/react-table";
import React from "react";
import { IAccountAccess } from "../../interfaces";

export const AccountAccessList: React.FC = () => {

    const columns = React.useMemo<ColumnDef<IAccountAccess>[]>(
        () => [
            {
                id: "userId",
                header: "User ID",
                accessorKey: "userId",
                enableColumnFilter: true,
            },
            {
                id: "accounts",
                header: "Linked Accounts",
                accessorKey: "accounts"
            }
        ],
        [],
    );

    return (
        <SimpleList columns={columns} actions={[ResourceAction.show, ResourceAction.edit, ResourceAction.delete]}/>
    )
};