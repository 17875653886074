export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_MSAL_CLIENT_ID!,
    authority: process.env.REACT_APP_MSAL_AUTHORITY, // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    knownAuthorities: [process.env.REACT_APP_MSAL_KNOWN_AUTHORITY!],
    redirectUri: process.env.REACT_APP_MSAL_REDIRECT,
    postLogoutRedirectUri: window.location.origin
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: [
    "offline_access",
    process.env.REACT_APP_MSAL_SCOPE!
  ]
}