import {
    UilClipboardNotes,
    UilCog,
    UilMegaphone,
    UilMobileAndroid,
    UilMobileVibrate,
    UilShield,
    UilUsersAlt,
    UilKeySkeleton,
    UilLinkAdd,
    UilComparison,
    UilLanguage,
    UilImport
} from "@iconscout/react-unicons";
import { ResourceProps } from "@refinedev/core";
import { FiHome, FiMail } from "react-icons/fi";

export const DoecoeResources: ResourceProps[] = [
    {
        name: "calltoaction",
        list: "/calltoaction",
        show: "/calltoaction/show/:id",
        create: "/calltoaction/create",
        edit: "/calltoaction/edit/:id",
        meta: {
            icon: <UilMegaphone size="18px" />,
            canDelete: true,
        },
    },
    {
        name: "appconfiguration",
        list: "/appconfiguration",
        edit: "/appconfiguration/edit/:id",
        meta: {
            icon: <UilCog size="18px" />,
        },
    },
    {
        name: "devices",
        list: "/devices",
        show: "/devices/show/:id",
        meta: {
            icon: <UilMobileAndroid size="18px" />,
            canDelete: true,
        },
    },
    {
        name: "messages",
        list: "/messages",
        show: "/messages/show/:id",
        create: "/messages/create",
        meta: {
            icon: <FiMail size="18px" />,
            canDelete: true,
        },
    },
    {
        name: "pushnotifications",
        list: "/pushnotifications",
        create: "/pushnotifications/create",
        meta: {
            icon: <UilMobileVibrate size="18px" />,
            canDelete: true,
        },
    },
    {
        name: "users",
        list: "/users",
        show: "/users/show/:id",
        create: "/users/create",
        edit: "/users/edit/:id",
        meta: {
            icon: <UilUsersAlt size="18px" />,
            canDelete: true,
        },
    },
    {
        name: "accountaccess",
        list: "/doecoe/accountaccess",
        create: "/doecoe/accountaccess/create",
        show: "/doecoe/accountaccess/show/:id",
        edit: "/doecoe/accountaccess/edit/:id",
        meta: {
            icon: <UilKeySkeleton size="18px" />,
            parent: "doecoe",
            canDelete: true,
        },
    },
    {
        name: "logs",
        list: "/logs",
        show: "/logs/show/:id",
        meta: {
            icon: <UilClipboardNotes size="18px" />,
        },
    },
    {
        name: "audits",
        list: "/security/audits",
        show: "/security/audits/show/:id",
        meta: {
            icon: <UilShield size="18px" />,
            parent: "security",
        },
    },
    {
        name: "localizations",
        list: "/localizations",
        // show: "/localizations/show/:id",
        meta: {
            icon: <UilLanguage size="18px" />,
        },
    },
    {
        name: "enrichments",
        list: "/positions/enrichments",
        create: "/positions/enrichments/create",
        show: "/positions/enrichments/show/:id",
        edit: "/positions/enrichments/edit/:id",
        meta: {
            icon: <UilLinkAdd size="18px" />,
            parent: "positions",
            canDelete: true,
        },
    },
    {
        name: "configuration",
        list: "/wealthforecast/configuration",
        meta: {
            icon: <UilComparison size="18px" />,
            parent: "wealthforecast",
        },
    },
    {
        name: "import",
        list: "/doecoe/account/import",
        meta: {
            icon: <UilImport size="18px" />,
            parent: "doecoe/account",
        },
    },
];
