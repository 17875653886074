import { VStack } from "@chakra-ui/react";
import { useShow } from "@refinedev/core";

import { DetailItem, Show } from "@bankingright-dashboard/ui";
import { IAccountAccess } from "interfaces";

export const AccountAccessShow: React.FC = () => {
    const { queryResult } = useShow<IAccountAccess>();
    const { data, isLoading } = queryResult;
    const access = data?.data;

    return (
        <Show isLoading={isLoading}>
            <VStack align="flex-start" spacing={3}>
                <DetailItem title="User ID" value={access?.userId} />
                <DetailItem title="Linked Accounts" value={access?.accounts?.join(",") ?? "error"} />
            </VStack>
        </Show>
    );
};
